<template>
  <div>
    <v-form ref="form">
      <br />
      <div>
        <h5 class="ml-1">Select estimate date</h5>
        <div class="small ml-2">
          Each day site estimates are pulled at the end of the day. These
          metrics will show missing estimates for each site and the given date.
        </div>
      </div>
      <v-row>
        <!-- <v-col cols="12" >
            Select a begin and end date
        </v-col> -->
        <v-col cols="6" md="6">
          <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="From"
                prepend-icon="event"
                readonly
                :value="fromDateDisp"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="en-in"
              v-model="fromDateVal"
              no-title
              @input="fromDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col cols="6" md="6">
          <v-menu
            v-model="toDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="To"
                prepend-icon="event"
                readonly
                :value="toDateDisp"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="en-in"
              v-model="toDateVal"
              no-title
              @input="toDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col> -->
      </v-row>
      <v-btn
        :ripple="false"
        :elevation="0"
        class="font-weight-bold text-xs btn-default bg-gradient-default"
        @click="showMissing(fromDateVal)"
        id="estimates_missing"
      >
        Show Missing</v-btn
      >
      <!-- <v-btn color="success" class="mr-4" @click="reloadAll()">
        Reload All</v-btn
      > -->
      <br />
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :headers="headers"
            :items="estimates"
            :items-per-page="5"
            class="elevation-1"
          >
            <!-- <template v-slot:item.controls="props">
              <v-btn
                class="mx-2"
                icon
                color="green"
                @click="refreshItem(props.item)"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template> -->
            <!-- <template v-slot:item.missing_site_tof="{ item }">
              {{ item.missing_site_tof ? "true" : "false" }}
            </template>
            <template v-slot:item.missing_inverter_tof="{ item }">
              {{ item.missing_inverter_tof ? "true" : "false" }}
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Service from "@/services/Service.js";

export default {
  components: {},
  data() {
    return {
      monitorSystem: null,
      props: null,
      toDateMenu: null,
      fromDateMenu: null,
      fromDateVal: null,
      toDateVal: null,
      items: ["Solaredge", "Fronius", "Solarlog", "Locus", "Also", "Enphase", "Powerdash", "GMP", "Allearth", "Auroravision", "Chint", "Egauge", "Pika", "Smasunnyportal", "Solectria"],
      headers: [
        { text: "Site ID", value: "id" },
        { text: "Name", value: "name" },
      ],
      estimates: [],
    };
  },
  beforeMount() {
    var date = new Date();
    this.toDateVal = this.$moment(date).format("YYYY-MM-DD");
    // this.toDateVal = today;
    console.log(`${this.$route.params.date}`);
    if (this.$route.params.date) {
      this.fromDateVal = this.$route.params.date;
      this.showMissing(this.$route.params.date);
    }
    var title = 'Missing Estimates'
    document.title = title
  },
  methods: {
    compareTime(time1, time2) {
      return new Date(time1) >= new Date(time2); // true if time1 is later
    },
    dataReload() {
      // var startDate = this.fromDateVal;
      // var endDate = this.toDateVal;
      // var system = this.monitorSystem.toLowerCase();
      // const params = {
      //   startDate,
      //   endDate,
      // };
      // // check to make sure to is greater than or equal to from
      // if(!this.compareTime(endDate, startDate)) {
      //   Vue.$toast.error(`Invalid date selection`, {position: "top-right"})
      //   return;
      // }
      // if(system == "locus") {
      //   Vue.$toast.warning(`Data reload for ${system} will only run for current date`, {position: "top-right", duration: 4000})
      // }
      // console.log(`From Date: ${startDate}, To Date: ${endDate}, System: ${system}`)
      // Service.getSiteDataForProvider(system, params)
      //   .then((response) => {
      //     Vue.$toast.success(`Data reload queued for ${system}`, {position: "top-right", duration: 4000})
      //   })
      //   .catch((error) => {
      //     Vue.$toast.error(`Error reloading data`, {position: "top-right"})
      //     console.error(error)
      //   })
    },
    showMissing(startDate) {
      Service.getEstimates(startDate)
        .then((response) => {
          this.estimates = response.data.estimates;
        })
        .catch((error) => {
          Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
    reloadAll() {
      // var startDate = this.fromDateVal;
      // var endDate = this.toDateVal;
      // var system = this.monitorSystem.toLowerCase();
      // const params = {
      //   startDate,
      //   endDate,
      // };
      // // check to make sure to is greater than or equal to from
      // if(!this.compareTime(endDate, startDate)) {
      //   Vue.$toast.error(`Invalid date selection`, {position: "top-right"})
      //   return;
      // }
      // console.log(`From Date: ${startDate}, To Date: ${endDate}, System: ${system}`)
      // NProgress.start()
      // Service.getSiteDataForProvider(system, params)
      //   .then((response) => {
      //       console.log(response.data)
      //       // this.sites = response.data.sites;
      //       NProgress.done();
      //       Vue.$toast.success(`Data reload queued for ${system}`, {position: "top-right", duration: 4000})
      //   })
      //   .catch((error) => {
      //     NProgress.done();
      //     Vue.$toast.error(`Error reloading data`, {position: "top-right"})
      //     console.error(error)
      //   })
    },
    refreshItem(item) {
      // var startDate = this.fromDateVal;
      // var endDate = this.toDateVal;
      // var system = this.monitorSystem.toLowerCase();
      // const params = {
      //   startDate,
      //   endDate,
      // };
      // // check to make sure to is greater than or equal to from
      // if(!this.compareTime(endDate, startDate)) {
      //   Vue.$toast.error(`Invalid date selection`, {position: "top-right"})
      //   return;
      // }
      // console.log(`From Date: ${startDate}, To Date: ${endDate}, System: ${system}`)
      // console.log(`site id: ${item.id}`)
      // Service.getSiteData(item.id, params)
      //   .then((response) => {
      //     Vue.$toast.success(`Data reload queued for site ${item.name}`, {position: "top-right", duration: 4000})
      //   })
      //   .catch((error) => {
      //     Vue.$toast.error(`Error reloading data`, {position: "top-right"})
      //     console.error(error)
      //   })
    },
  },
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    toDateDisp() {
      return this.toDateVal;
    },
  },
};
</script>

<style>
#estimates_missing {
  margin-left: 0.5%;
  margin-bottom: 0.5%;
}
</style>